<script>
  import { alert, authed, dateOptions, inform, online, prompt, router, t } from '@dabble/app';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiClose, mdiLoading } from '@mdi/js';
  import { format } from 'date-fns';
  import {
    billing,
    discountAvailable,
    lifetimePlan,
    lifetimePrice,
    plans,
    subscription,
    trialAvailable,
  } from '../billing';
  import Card from './Card.svelte';
  import CardInput from './CardInput.svelte';
  import { couponAmount, couponDuration, formatPrice } from './util';

  export let redirect;
  const DAY = 1000 * 60 * 60 * 24;
  let planId = router && router.getQuery().get('plan');
  let product;
  let plan;
  let payment;
  let newPromo;
  let newCoupon;
  let saving;
  let disabled;
  let newCard;
  let invoice;
  let call = 0;
  let cardInput;
  let error;
  let beginDispatched;

  $: discount = $subscription && $subscription.discount;
  $: existingCoupon = discount && discount.coupon;
  $: promoCode =
    (newPromo && newPromo.code) ||
    (newCoupon && newCoupon.id) ||
    (discount && (discount.promotion_code || (existingCoupon && existingCoupon.id)));
  $: payment = $billing.payment;
  $: disabled = !$authed || saving;
  $: plan = $plans.all && $plans.all[planId];
  $: isLifetime = plan === $lifetimePlan;
  $: price = isLifetime ? $lifetimePrice : plan;
  $: product = plan && $plans.all[plan.product];
  $: if ($authed && $discountAvailable && !isLifetime && !existingCoupon && !newCoupon && !saving)
    applyEarlySubscriber();
  $: $authed && plan && updateInvoice(plan, newCoupon || existingCoupon);
  $: newCardRequired = !payment;
  $: isTrialAvailable = $trialAvailable && !isLifetime;
  $: if (!beginDispatched && plan && invoice) dispatchBeginCheckout();
  $: if (sessionStorage.referralCode || sessionStorage.coupon) applySavedCode();

  function applySavedCode() {
    applyCoupon(sessionStorage.referralCode || sessionStorage.coupon);
  }

  window.dispatchEvent(new CustomEvent('currentscreen', { detail: { name: 'Checkout' } }));

  function dispatchBeginCheckout() {
    window.dispatchEvent(
      new CustomEvent('begincheckout', {
        detail: { plan, coupon: newCoupon || existingCoupon, total: invoice, lifetime: isLifetime },
      })
    );
    beginDispatched = true;
  }

  async function applyEarlySubscriber() {
    // await applyCoupon('early-subscriber');
  }

  async function applyCoupon(code) {
    if (!code) code = (await prompt($t('prompt_discount_code'), '', { placeholder: 'COUPON' })).trim();
    if (!code) return;
    saving = true;
    let result;
    try {
      result = await billing.getCoupon(code, plan.id);
    } catch (err) {
      inform('danger', $t(err.message));
      saving = false;
      return;
    }
    if (result && result.id.startsWith('promo_')) {
      newPromo = result;
      newCoupon = newPromo.coupon;
    } else {
      inform('danger', $t('COUPON_INVALID'));
      saving = false;
      return;
    }
    inform('success', $t('billing_coupon_applied'));
    saving = false;
  }

  async function updateInvoice(plan, coupon) {
    const thisCall = ++call; // may have more than one call triggered to run, only pay attention to the last one
    try {
      const result = await billing.getUpcomingInvoice({ plan: plan.id, coupon: coupon && coupon.id });
      if (thisCall === call) {
        invoice = result;
      }
    } catch (err) {
      if (thisCall === call) {
        if (err.message.includes('combine currencies')) {
          error = 'CANNOT_COMBINE_CURRENCIES';
        } else {
          console.error(err);
          error = err.message;
        }
      }
    }
  }

  function getDue(subscription, invoice) {
    if (isTrialAvailable) {
      const date = $trialAvailable < 1000 ? new Date(Date.now() + $trialAvailable * DAY) : new Date($trialAvailable);
      return $t('checkout_due_date_not', { date: format(date, 'PP', dateOptions) });
    }
    if (invoice.amount_due < 0) {
      return $t('checkout_applied_to_your_account');
    }
    if (!subscription) {
      return $t('checkout_due_now');
    }
    if (subscription && subscription.status === 'trialing') {
      return $t('checkout_due_date_not', { date: format(subscription.trial_end * 1000, 'PP', dateOptions) });
    }
    if (invoice && invoice.next_payment_attempt) {
      return $t('checkout_due_date', { date: format(invoice.next_payment_attempt * 1000, 'PP', dateOptions) });
    }

    return '';
  }

  function getDueDate(subscription, trialAvailable) {
    if (trialAvailable) {
      const date = trialAvailable < 1000 ? new Date(Date.now() + trialAvailable * DAY) : new Date(trialAvailable);
      return date;
    }
    if (subscription && subscription.status === 'trialing') {
      return new Date(subscription.trial_end * 1000);
    }
    return '';
  }

  async function checkout() {
    saving = true;
    error = null;
    let failed = false;
    let card;
    const sub = $subscription;
    const total = (invoice && invoice.total / 100) || 0;
    const coupon = (newPromo && newPromo.id) || (newCoupon && newCoupon.id) || undefined;

    try {
      card = await getPayment();
      if ((coupon || $subscription?.discount?.coupon) && total === 0) {
        await billing.updateSubscription({ plan: plan.id, coupon });
      } else if (isTrialAvailable && (card || cardInput.card.empty)) {
        await billing.createSubscription({ plan: plan.id, coupon });
      } else if (card) {
        await billing.updateSubscription({ plan: plan.id, coupon });
      } else {
        failed = true;
        error = 'PAYMENT_REQUIRED';
      }
    } catch (err) {
      error = err.message;
      failed = true;
    }
    saving = false;
    if (!failed) {
      const type = getType(sub, $subscription);
      const oldPrice = type !== 'new' ? getBasePrice(sub.plan) : 0;
      const newPrice = getBasePrice($subscription.plan);
      const priceDiff = newPrice - oldPrice; // Will be the monthly plan equivalent
      window.dispatchEvent(
        new CustomEvent('checkout', {
          detail: {
            type,
            plan,
            coupon: newCoupon,
            total,
            lifetime: isLifetime,
            priceDiff,
            subTotal: invoice.subtotal,
            invoiceNumber: billing.get().latest_invoice,
          },
        })
      );
      sessionStorage.removeItem('referralCode');
      sessionStorage.removeItem('coupon');
      router.navigate(redirect || '/account/billing');
      setTimeout(() => {
        if (type === 'downgrade') {
          alert($t('checkout_success_downgrade_title'), $t('checkout_success_downgrade_body'));
        } else {
          alert($t('checkout_success_title'), $t('checkout_success_body'), { fireworks: true });
        }
      }, 500);
    }
  }

  async function getPayment() {
    if (cardInput && !cardInput.card.empty) {
      const paymentMethodId = await cardInput.save();
      if (!paymentMethodId) return;
      const updated = await billing.attachPaymentMethod(paymentMethodId);
      return updated.payment;
    } else {
      return $billing.payment;
    }
  }

  function getType(oldSub, newSub) {
    if (!oldSub || oldSub.status === 'canceled' || oldSub.status === 'trialing') {
      return 'new';
    }
    const oldPrice = getBasePrice(oldSub.plan);
    const newPrice = getBasePrice(newSub.plan);

    // A downgrade is a lower plan or moving from yearly to monthly, an upgrade is a higher plan or monthly to annually
    if (oldPrice > newPrice || (oldPrice === newPrice && newSub.plan.interval === 'month')) {
      return 'downgrade';
    } else {
      return 'upgrade';
    }
  }

  function getBasePrice(plan) {
    let amount = plan.amount;
    if (plan.interval === 'yearly') {
      amount = amount / 0.8 / 12; // monthly eq.
    }
    return amount;
  }
</script>

{#if plan}
  <div class="confirm-screen">
    <h2>{$t('checkout_checkout')}</h2>
    {#if error}
      <Alert type="danger" dismissible on:close={() => (error = null)}>
        <strong>{$t('error')}:</strong>
        {error && error.includes(':') ? error : $t(error)}
      </Alert>
    {/if}
    <div class="content">
      {#if !$authed}
        <span class="offline-indicator badge danger">{$t($online ? 'account_disconnected' : 'account_offline')}</span>
      {/if}
      <div class="billing">
        <h3 class="section title">{$t('checkout_payment_information')}</h3>
        {#if !newCardRequired && !newCard}
          <div class="payment">
            <Card card={$billing.payment} />
          </div>
          <div class="billing-actions">
            <button class="btn link" on:click={() => (newCard = true)} {disabled}>{$t('checkout_another_card')}</button>
          </div>
        {:else}
          <div class="card">
            <CardInput bind:this={cardInput} />
            {#if !newCardRequired}
              <button class="icon" on:click={() => (newCard = false)} use:tooltipTop={$t('billing_cancel')} {disabled}>
                <Icon path={mdiClose} />
              </button>
            {/if}
          </div>
          {#if isTrialAvailable}
            <div class="payment-unnecessary">{$t('billing_not_charged')}</div>
          {/if}
        {/if}
      </div>

      <div class="cart">
        <h3 class="section title">{$t('checkout_your_order')}</h3>
        <div class="section subscription">
          <div class="image {product.name.toLowerCase().replace(/\s+/g, '-')}" />
          <div class="description">
            <div class="name">{product.name}</div>
            <div class="interval">{$t(`checkout_interval_${plan.interval}_subscription`)}</div>
          </div>
          <div class="price">
            {formatPrice(price.amount || price.unit_amount, plan.currency)}
          </div>
        </div>

        <div class="section totals">
          {#if invoice}
            {#each invoice.lines.data as item}
              <div class="line">
                <div class="label">{item.description}</div>
                <div class="price">{formatPrice(item.amount, invoice.currency)}</div>
              </div>
            {/each}
            <div class="line emphasis">
              <div class="label">{$t('checkout_subtotal')}</div>
              <div class="price">{formatPrice(invoice.subtotal, invoice.currency)}</div>
            </div>
            <hr />
            {#if invoice.discount}
              <div class="line">
                <div class="label">
                  {invoice.discount.coupon.name ||
                    invoice.discount.promotion_code ||
                    invoice.discount.coupon.id.toUpperCase()}
                  <div class="info">
                    {couponAmount(invoice.discount.coupon, true)}
                    {couponDuration(invoice.discount.coupon)}
                  </div>
                </div>
                <div class="price">({couponAmount(invoice.discount.coupon)})</div>
              </div>
            {/if}
            <div class="line">
              <div class="label">
                {$t('checkout_total')}
              </div>
              <div class="price">{formatPrice(invoice.total, invoice.currency)}</div>
            </div>
            {#if invoice.customer?.balance && invoice.total}
              <div class="line">
                <div class="label">
                  {$t('checkout_applied_balance')}
                </div>
                <div class="price">
                  {formatPrice(Math.max(-invoice.total, invoice.customer.balance), invoice.currency)}
                </div>
              </div>
            {/if}
            <div class="line emphasis">
              <div class="label">
                {$t('checkout_amount_due')}
                <div class="info">{getDue($subscription, invoice)}</div>
              </div>
              <div class="price">{formatPrice(invoice.amount_due, invoice.currency)}</div>
            </div>
          {/if}
        </div>
        <div class="cart-actions">
          <!-- {#if !isLifetime} -->
          <button class="btn link apply-coupon-link" on:click={() => applyCoupon()} {disabled}
            >{$t(`billing_coupon_have${newCoupon ? '_another' : ''}`)}</button
          >
          <!-- {/if} -->
        </div>
      </div>
    </div>

    <div class="actions">
      <a href="/checkout/plans" class="btn secondary">{$t('billing_change_plan')}</a>
      <button class="btn primary large" on:click={checkout} {disabled}>
        {#if saving}
          <Icon path={mdiLoading} spin />
        {/if}
        {isTrialAvailable ? $t('checkout_start_trial') : $t('checkout_now')}
      </button>
    </div>
    {#if (($subscription && $subscription.status === 'trialing') || isTrialAvailable) && !isLifetime}
      <div class="checkout-info info">
        {$t('checkout_due_not_charged_until', {
          date: format(getDueDate($subscription, $trialAvailable), 'PP', dateOptions),
        })}
      </div>
    {/if}
  </div>
{/if}

<style>
  .confirm-screen {
    padding: 0 10px 20px;
  }
  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  .content {
    position: relative;
    display: flex;
    margin-bottom: 20px;
  }
  .offline-indicator {
    position: absolute;
    top: -14px;
    right: -4px;
    z-index: 1;
    box-shadow: var(--flat-box-shadow);
  }
  .billing {
    flex: 1 1;
    padding: 20px 0;
  }
  .payment {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  .cart {
    flex: 1 1;
    margin-left: 40px;
    padding: 20px;
  }
  .cart hr {
    margin: 4px 0;
  }
  .title {
    text-transform: uppercase;
    font-size: var(--font-size-sm);
    color: var(--text-color-light);
    margin: 0;
    padding: 4px 0;
  }
  .section {
    border-bottom: 1px solid var(--dabble-gray);
  }
  .card {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .subscription {
    display: flex;
    align-items: center;
    padding: 8px 0 12px;
  }
  .image {
    width: 50px;
    height: 62px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-image: url(../images/plan-standard.svg);
    transform: translate(0, 6px);
  }
  .image.basic {
    background-image: url(../images/plan-basic.svg);
  }
  .image.standard {
    background-image: url(../images/plan-standard.svg);
  }
  .image.premium,
  .image.lifetime-subscription {
    background-image: url(../images/plan-premium.svg);
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-right: 10px;
  }
  .name {
    font-weight: bold;
  }
  .interval {
    color: var(--text-color-lightest);
    font-size: var(--font-size-sm);
  }
  .price {
    text-align: right;
    font-size: var(--font-size-sm);
  }
  .subscription .price {
    font-weight: bold;
  }
  .line {
    display: flex;
    padding: 4px 0;
  }
  .label {
    flex: 1;
    font-size: var(--font-size-sm);
    color: var(--text-color-light);
  }
  .line.emphasis .label,
  .line.emphasis .price {
    font-weight: bold;
  }
  .due {
    font-weight: normal;
    color: var(--text-color-lightest);
    margin-left: 4px;
  }
  .info {
    font-weight: normal;
    color: var(--text-color-lightest);
    font-size: var(--font-size-xs);
  }
  .payment-unnecessary {
    margin-top: 8px;
    font-weight: normal;
    color: var(--text-color-lightest);
    font-size: var(--font-size-sm);
  }
  .billing-actions {
    /* margin: 20px 0; */
    font-size: var(--font-size-sm);
  }
  .cart-actions {
    margin: 20px 0;
    text-align: right;
    font-size: var(--font-size-sm);
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .checkout-info {
    text-align: right;
    margin-top: 10px;
  }
  @media (max-width: 688px) {
    .content {
      flex-direction: column;
    }
    .cart {
      margin: 20px 0 0;
    }
  }
</style>
