import { EventDispatcher } from 'typewriter-editor';
import { t } from './app';
import { Writable, writable } from './data/stores/store';
import { AppUpdate } from './data/types';
import { Globals } from './data/ui';
import log from './util/log';
import { compareVersions } from './util/versions';

const UPDATE_CHECK_INTERVAL = 60000 * 30; // 30 minutes
const WIN_DESKTOP_LINK = 'https://files.dabblewriter.com/releases/win/download';

interface DesktopUI {
  appUpdate: Writable<AppUpdate>;
  globals: Globals;
}

declare global {
  interface Window {
    electron?: {
      onMessage(fn: (name: string, data: any) => any): void;
      send(name: string, data?: any): void;
    };
  }
}

class Desktop extends EventDispatcher {
  inApp = false;
  oldApp = false;
  active: boolean;
  updateCheck: any;
  version: string;
  isFullscreen: Writable<boolean>;
  ui: DesktopUI = null;

  constructor() {
    super();
    this.active = false;
    setup(this);
    if (this.inApp) {
      this.init();
      window.addEventListener('message', this.onMessage);
      this.updateCheck = setInterval(() => this.checkForUpdates, UPDATE_CHECK_INTERVAL);
      this.checkForUpdates();
    }
    window.addEventListener('fullscreenchange', () => this.isFullscreen.set(isFullscreen()));
    this.isFullscreen = writable(isFullscreen());
  }

  close() {
    window.removeEventListener('message', this.onMessage);
    clearInterval(this.updateCheck);
  }

  toggleFullscreen = (): void => {
    if (this.isFullscreen.get()) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  };

  checkForUpdates = (): void => {
    this.send('check-for-updates');
  };

  quitAndInstall(): void {
    this.send('quit-and-install');
  }

  send(name: string, data?: any): void {
    if (window.electron) window.electron.send(name, data);
    else if (this.active) window.parent.postMessage({ name, data }, 'file://');
  }

  onMessage = (event: MessageEvent) => {
    if (event.origin !== 'file://') return;
    if (!this.active) this.init();
    const { name, data } = event.data;
    this.dispatchEvent(new CustomEvent(name, { detail: data }));
  };

  init() {
    if (this.active) return;
    const desktop = this as Desktop;
    this.active = true;

    desktop.quitAndInstall = function () {
      desktop.send('quit-and-install');
    };

    desktop.on('log', ({ detail }: CustomEvent) => {
      log(...detail);
    });

    desktop.on('notify', ({ detail: { title, message } }: CustomEvent) => {
      desktop.ui.globals.alert(title, message);
    });

    desktop.on('info', ({ detail: { version, platform } }: CustomEvent) => {
      if (!version || compareVersions(version, '1.6.0') < 0) {
        desktop.ui.globals.alert(t.get()('alert_header_old_desktop'), t.get()('alert_old_desktop'));
      }
      desktop.version = version;
      log.tagColor('Load', '#444', 'Dabble Desktop v' + version);
      desktop.ui.appUpdate.update((data: any) => {
        return { ...data, desktopVersion: version, platform };
      });

      desktop.toggleFullscreen = function () {
        desktop.send('toggleFullscreen');
      };

      desktop.on('isFullscreen', ({ detail }: CustomEvent) => {
        desktop.isFullscreen.set(detail);
      });
    });

    desktop.on('reload', () => {
      window.location.reload();
    });

    desktop.on('update-state', ({ detail: info }: CustomEvent) => {
      const { state, version, progress } = info;

      desktop.ui.appUpdate.update((data: any) => {
        return {
          ...data,
          location: 'desktop',
          state,
          newVersion: version,
          progress: (progress && Math.round(progress.percent * 100) / 100) || 0,
        };
      });

      if (state === 'installed') {
        clearInterval(desktop.updateCheck);
      }
    });
  }
}

export const desktop = new Desktop();

function setup(desktop: Desktop) {
  if (window.electron) {
    try {
      desktop.inApp = true;
      window.electron.onMessage((name: string, data: any) => {
        desktop.dispatchEvent(new CustomEvent(name, { detail: data }));
      });
    } catch (e) {}
  }
}

function requestFullscreen() {
  const elem = document.documentElement as any;
  if (elem.requestFullscreen) {
    return elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    return elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    return elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    return elem.msRequestFullscreen();
  }
}

function exitFullscreen() {
  const doc = document as any;
  if (doc.exitFullscreen) {
    return doc.exitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    /* Firefox */
    return doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    return doc.webkitExitFullscreen();
  } else if (doc.msExitFullscreen) {
    /* IE/Edge */
    return doc.msExitFullscreen();
  }
}

function isFullscreen() {
  try {
    return !!document.fullscreenElement;
  } catch (err) {}
  return false;
}
